import { fetchFederation, fetchPartnerships } from 'modules/federation/actions';
import { getFederation } from 'modules/federation/selectors';
import { FederationDocData } from 'modules/federation/types';
import { ModuleListActions, ModuleViewActions } from 'modules/firestore';
import { ApplicationState } from 'modules/root';
import { createContext, FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';

export const FederationContext = createContext<FederationDocData | null>(null);

export const FederationProvider: FC = ({ children }) => {
	const dispatch = useDispatch();

	const federation = useSelector((state: ApplicationState) =>
		getFederation(state)
	);

	useMount(() => {
		dispatch(fetchFederation(ModuleViewActions.INIT));
		dispatch(fetchPartnerships(ModuleListActions.INIT));
	});

	if (!federation) {
		return null;
	}

	// @TODO error

	return (
		<FederationContext.Provider value={federation}>
			{children}
		</FederationContext.Provider>
	);
};

export const useFederation = () => {
	const context = useContext(FederationContext);
	if (context === null) {
		throw new Error(
			`FederationContext must be used within a FederationProvider`
		);
	}
	return context;
};
