import { AuthFbFunctionsTypes as FnFbTypes } from '@glissandoo/lib/functions/auth';

const status = async () => {
	const res = await fetch('/auth/status', {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
	});

	const { idToken } = (await res.json()) as FnFbTypes.StatusResult;
	return idToken;
};

const logout = async () => {
	await fetch('/auth/logout', {
		method: 'POST',
		mode: 'cors',
		credentials: 'include',
	});

	return Promise.resolve();
};

export const auth = {
	status,
	logout,
};
