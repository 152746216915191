import { FbFunctionName } from '@glissandoo/lib/functions';
import { getFunctionRegion } from '@glissandoo/lib/functions/regions';
import firebase from 'firebase/app';

const createFunction = <T, R>(
	name: FbFunctionName
): ((data: T) => Promise<R>) => {
	const region = getFunctionRegion(name);
	const functions = firebase.app().functions(region);
	if (process.env.NODE_ENV !== 'production') {
		functions.useEmulator('localhost', 5001);
	}
	const callable = functions.httpsCallable(name);
	return async (data: T) => {
		try {
			const result = await callable(data);
			return result.data;
		} catch (e) {
			return Promise.reject(e);
		}
	};
};

export const callFunction = <D, R>(data: D, name: FbFunctionName) => {
	const fn = createFunction<D, R>(name);
	return fn(data);
};
