import { ApplicationState } from 'modules/root';

export const getFederation = ({ federation }: ApplicationState) => {
	return federation.view.data;
};

export const getPartnerships = ({ federation }: ApplicationState) =>
	federation.partnerships.items;

export const getPartnership = (state: ApplicationState, id: string) => {
	const partnerships = getPartnerships(state);
	return partnerships.find((partnership) => partnership.id === id);
};
