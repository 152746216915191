import 'firebase/analytics';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import { configureStore, history } from 'modules/store';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import LanguageProvider from './lang';
import Router from './router';

firebase.initializeApp({
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});
firebase
	.analytics()
	.setAnalyticsCollectionEnabled(process.env.NODE_ENV === 'production');

const store = configureStore();

const App = () => (
	<Provider store={store}>
		<LanguageProvider>
			<IntercomProvider
				appId={process.env.REACT_APP_INTERCOM_ID as string}
				autoBoot
			>
				<Router history={history} />
			</IntercomProvider>
		</LanguageProvider>
	</Provider>
);

export default App;
