import { Grid, Layout } from 'antd';
import { FC, useEffect, useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import './styles.less';

const LayoutDashboard: FC = ({ children }) => {
	const { xl } = Grid.useBreakpoint();
	const [collapsed, setCollapsed] = useState(false);

	useEffect(() => {
		setCollapsed(!xl);
	}, [xl]);

	return (
		<Layout className="layout-dashboard">
			<Sidebar collapsed={collapsed} />
			<Layout className="site-layout">
				<Header setCollapsed={setCollapsed} collapsed={collapsed} />
				<Layout.Content className="layout-dashboard-content min-h-screen">
					{children}
				</Layout.Content>
			</Layout>
		</Layout>
	);
};

export default LayoutDashboard;
