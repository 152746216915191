import { LanguagesTypes } from '@glissandoo/lib/lang';
import { Action } from 'redux';

export interface AppState {
	lang: LanguagesTypes;
}

export enum AppTypes {
	SET_LANG = 'SET_LANG',
	CLEAR_APP = 'CLEAR_APP',
}

interface SetLangAction extends Action<AppTypes> {
	type: AppTypes.SET_LANG;
	payload: LanguagesTypes;
}

interface ClearAppAction extends Action<AppTypes> {
	type: AppTypes.CLEAR_APP;
}

export type AppActions = SetLangAction | ClearAppAction;
