import { FbFunctionName } from '@glissandoo/lib/functions';
import { FederationFbFunctionsTypes as Types } from '@glissandoo/lib/functions/federation';
import Federation from '@glissandoo/lib/models/Federation';
import { DocumentData, DocumentSnapshot } from '@google-cloud/firestore';
import firebase from 'firebase/app';
import { callFunction } from './index';

const model = (doc: firebase.firestore.DocumentSnapshot) =>
	new Federation(doc as unknown as DocumentSnapshot<DocumentData>);

const edit = (data: Types.EditParams) =>
	callFunction<Types.EditParams, Types.EditResult>(
		data,
		FbFunctionName.FederationEdit
	);

const addPendingAdmin = (data: Types.AddPendingAdminParams) =>
	callFunction<Types.AddPendingAdminParams, Types.AddPendingAdminResult>(
		data,
		FbFunctionName.FederationAddPendingAdmin
	);

const removeAdmin = (data: Types.RemoveAdminParams) =>
	callFunction<Types.RemoveAdminParams, Types.RemoveAdminResult>(
		data,
		FbFunctionName.FederationRemoveAdmin
	);

const confirmAdmin = (data: Types.ConfirmAdminParams) =>
	callFunction<Types.ConfirmAdminParams, Types.ConfirmAdminResult>(
		data,
		FbFunctionName.FederationConfirmAdmin
	);

const getEvents = (data: Types.GetEventsParams) =>
	callFunction<Types.GetEventsParams, Types.GetEventsResult>(
		data,
		FbFunctionName.FederationGetEvents
	);

export const federationDB = {
	model,
	edit,
	addPendingAdmin,
	removeAdmin,
	confirmAdmin,
	getEvents,
};
