import { Image, Typography } from 'antd';
import { USER_PHOTO_DEFAULT } from 'helpers/constants';
import { useAuth } from 'router/AuthContext';
import LogoutButton from './Logout';

const UserMenu = () => {
	const auth = useAuth();

	return (
		<div className="flex items-center">
			<Image
				src={auth.photoURL}
				alt={auth.displayName}
				width={48}
				height={48}
				preview={false}
				className="rounded-full"
				fallback={USER_PHOTO_DEFAULT}
			/>
			<div className="leading-5 mt-1 ml-4">
				<Typography.Text>{auth.displayName}</Typography.Text>
				<br />
				<LogoutButton />
			</div>
		</div>
	);
};

export default UserMenu;
