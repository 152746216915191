import { defaultLocale, LanguagesTypes } from '@glissandoo/lib/lang';
import { Locale as LocaleAntd } from 'antd/lib/locale-provider';
import { Locale as LocaleFNS } from 'date-fns';
import EnLang from './entries/en';
import EsLang from './entries/es';

export interface LocaleLang {
	messages: Record<string, string>;
	antd: LocaleAntd;
	locale: LanguagesTypes;
	dateFns: LocaleFNS;
}

const locales: Record<LanguagesTypes, LocaleLang> = {
	en: EnLang,
	es: EsLang,
	ca: EsLang,
	gl: EsLang,
	de: EnLang,
	eu: EnLang,
	pt: EsLang,
};

export const getLocale = (lang?: LanguagesTypes) => {
	if (!lang || !locales[lang]) {
		return locales[defaultLocale];
	}
	return locales[lang];
};

export const supportedLocales = Object.values(LanguagesTypes);
export default locales;
