import update from 'immutability-helper';
import { initialViewState } from 'modules/firestore';
import { Reducer } from 'redux';
import { AuthActions, AuthState, AuthTypes } from './types';

export const initialState: AuthState = {
	user: initialViewState,
	logged: undefined,
};

const authReducer: Reducer<AuthState, AuthActions> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case AuthTypes.SET_USER: {
			return update(state, {
				user: { $set: action.payload.value },
			});
		}

		case AuthTypes.UPDATE_USER: {
			return update(state, {
				user: {
					data: { $merge: action.payload },
				},
			});
		}

		case AuthTypes.SET_LOGGED: {
			return update(state, {
				logged: { $set: action.payload },
			});
		}

		case AuthTypes.CLEAR_ME: {
			return update(state, { $set: initialState });
		}

		default:
			return state;
	}
};

export default authReducer;
