import Logo from 'assets/images/logo.png';
import LayoutCentered from 'layouts/Centered';
import './styles.less';

const LoadingScreen = () => {
	return (
		<LayoutCentered>
			<img
				className="animate__animated animate__bounce animate__infinite"
				width={72}
				height={72}
				src={Logo}
				alt="glissandoo"
			/>
			<div className="logo-shadow" />
		</LayoutCentered>
	);
};

export default LoadingScreen;
