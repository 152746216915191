import { History } from 'history';
import { authActions } from 'modules/auth/actions';
import { isLoaded } from 'modules/auth/selectors';
import { ApplicationState, useAppDispatch } from 'modules/root';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Router } from 'react-router-dom';
import { useMount } from 'react-use';
import LoadingScreen from 'routes/Loading';
import { AuthProvider } from './AuthContext';
import Routes from './dashboard';

interface PublicRoutesProps {
	history: History;
}

const DashboardRoutes: FC<PublicRoutesProps> = ({ history }) => {
	const dispatch = useAppDispatch();

	const isLogged = useSelector((state: ApplicationState) => isLoaded(state));

	useMount(() => {
		dispatch(authActions.init());
	});

	if (!isLogged) {
		return <LoadingScreen />;
	}

	return (
		<Router history={history}>
			<AuthProvider>
				<Routes />
			</AuthProvider>
		</Router>
	);
};

export default DashboardRoutes;
