import { createBrowserHistory } from 'history';
import createRootReducer, { ApplicationState } from 'modules/root';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';

export const history = createBrowserHistory();

export const configureStore = (): Store<ApplicationState> => {
	const middlewares = [thunk];

	return createStore(
		createRootReducer(),
		compose(applyMiddleware(...middlewares))
	);
};
