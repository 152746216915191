import Federation from '@glissandoo/lib/models/Federation';
import FederationPartnership from '@glissandoo/lib/models/Federation/Partnership';
import { FetchListState, FetchViewState } from 'modules/firestore';
import { Action } from 'redux';

export enum FederationTypes {
	SET_VIEW_FEDERATION = 'SET_VIEW_FEDERATION',
	CLEAR_FEDERATION = 'CLEAR_FEDERATION',
	UPDATE_LIST_PARTNERSHIPS = 'UPDATE_LIST_PARTNERSHIPS',
}

export enum FederationParams {
	Id = 'id',
	Displayname = 'displayName',
	PhotoURL = 'photoURL',
	Email = 'email',
	Web = 'web',
	Phone = 'phone',
	Address = 'address',
	AdminIds = 'adminIds',
	AdminList = 'adminList',
}
export type FederationDocData = Pick<Federation, FederationParams>;

export enum FederationPartnershipParams {
	Id = 'id',
	Displayname = 'displayName',
	PhotoURL = 'photoURL',
	Address = 'address',
	Email = 'email',
	CIF = 'CIF',
	Phone = 'phone',
	MemberCount = 'memberCount',
	PartnerCount = 'partnerCount',
	LeaderCount = 'leaderCount',
	HasSchool = 'hasSchool',
	TeacherCount = 'teacherCount',
	StudentCount = 'studentCount',
	IsConnected = 'isConnected',
	UpdatedAt = 'updatedAt',
}
export type FederationPartnershipDocData = Pick<
	FederationPartnership,
	FederationPartnershipParams
>;

interface SetViewFederation extends Action {
	type: FederationTypes.SET_VIEW_FEDERATION;
	payload: {
		id: string;
		value: Partial<FetchViewState<FederationDocData>>;
	};
}

interface UpdateListFederationPartnershipAction extends Action {
	type: FederationTypes.UPDATE_LIST_PARTNERSHIPS;
	payload: Partial<FetchListState<FederationPartnershipDocData>>;
}

interface ClearFederationAction extends Action {
	type: FederationTypes.CLEAR_FEDERATION;
}

export type FederationAction =
	| ClearFederationAction
	| UpdateListFederationPartnershipAction
	| SetViewFederation;
