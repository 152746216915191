import { CollectionNames } from '@glissandoo/lib/helpers/collections';
import firebase from 'firebase/app';
import { federationDB } from 'functions/federation';
import { federationPartnershipDB } from 'functions/federationPartnership';
import pick from 'lodash/pick';
import { getUser } from 'modules/auth/selectors';
import {
	getFirestoreListHandler,
	getFirestoreViewHandler,
	ModuleListActions,
	ModuleViewActions,
} from 'modules/firestore';
import { ApplicationState } from 'modules/root';
import { ThunkAction } from 'redux-thunk';
import {
	FederationAction,
	FederationDocData,
	FederationParams,
	FederationPartnershipDocData,
	FederationPartnershipParams,
	FederationTypes,
} from './types';

type ThunkResult<R> = ThunkAction<
	R,
	ApplicationState,
	undefined,
	FederationAction
>;

const formatPartnershipsList = (
	doc: firebase.firestore.DocumentSnapshot
): FederationPartnershipDocData => {
	const model = federationPartnershipDB.model(doc);
	const params = Object.values(FederationPartnershipParams);
	return pick(model, params);
};

export const fetchPartnerships =
	(action: ModuleListActions): ThunkResult<Promise<void>> =>
	(dispatch, getState) => {
		const state = getState();
		const user = getUser(state);

		if (!user || !user.federationId) {
			return Promise.resolve();
		}

		const endpoint = firebase
			.firestore()
			.collection(CollectionNames.Federation)
			.doc(user.federationId)
			.collection(CollectionNames.FederationPartnerships);

		dispatch(
			getFirestoreListHandler({
				action,
				list: state.federation.partnerships,
				type: FederationTypes.UPDATE_LIST_PARTNERSHIPS,
				endpoint,
				formatItem: formatPartnershipsList,
				itemsByPage: 500,
			})
		);

		return Promise.resolve();
	};

const formatFederationView = (
	doc: firebase.firestore.DocumentSnapshot
): FederationDocData => {
	const model = federationDB.model(doc);
	const params = Object.values(FederationParams);
	return pick(model, params);
};

export const fetchFederation =
	(action: ModuleViewActions): ThunkResult<Promise<void>> =>
	(dispatch, getState) => {
		const state = getState();
		const user = getUser(state);

		if (!user || !user.federationId) {
			return Promise.resolve();
		}

		const endpoint = firebase
			.firestore()
			.collection(CollectionNames.Federation)
			.doc(user.federationId);

		dispatch(
			getFirestoreViewHandler({
				action,
				view: state.federation.view,
				type: FederationTypes.SET_VIEW_FEDERATION,
				endpoint,
				formatView: formatFederationView,
			})
		);

		return Promise.resolve();
	};
