import { Spin, Typography } from 'antd';
import message from 'antd/lib/message';
import Modal from 'antd/lib/modal';
import firebase from 'firebase/app';
import { auth } from 'functions/auth';
import { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const LogoutButton = () => {
	const [visible, setVisible] = useState(false);

	const onLogout = async () => {
		try {
			setVisible(true);
			await auth.logout();
			await firebase.auth().signOut();
			window.location.href = 'https://glissandoo.com';
			return Promise.resolve();
		} catch (error: unknown | Error) {
			if (error instanceof Error) {
				message.error(error.message);
			}
			setVisible(false);
		}
	};

	return (
		<Fragment>
			<Typography.Link type="danger" onClick={onLogout}>
				<FormattedMessage id="logout.button" />
			</Typography.Link>
			<Modal visible={visible} width={300} closable={false} footer={null}>
				<div className="flex flex-col justify-center items-center p-4">
					<Spin />
					<Typography.Text className="mt-6" type="secondary">
						<FormattedMessage id="logout.button" />
					</Typography.Text>
				</div>
			</Modal>
		</Fragment>
	);
};

export default LogoutButton;
