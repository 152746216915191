import { LanguagesTypes } from '@glissandoo/lib/lang';
import antd from 'antd/lib/locale/en_US';
import dateFns from 'date-fns/locale/en-US';
import messages from '../locales/en.json';

const EnLang = {
	messages,
	antd,
	locale: LanguagesTypes.EN,
	dateFns,
};

export default EnLang;
