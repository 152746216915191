import { FbFunctionName } from '@glissandoo/lib/functions';
import { FederationPartnershipFbFunctionsTypes as Types } from '@glissandoo/lib/functions/federationPartnership';
import FederationPartnership from '@glissandoo/lib/models/Federation/Partnership';
import { DocumentData, DocumentSnapshot } from '@google-cloud/firestore';
import firebase from 'firebase/app';
import { callFunction } from './index';

const model = (doc: firebase.firestore.DocumentSnapshot) =>
	new FederationPartnership(doc as unknown as DocumentSnapshot<DocumentData>);

const addManual = (data: Types.AddManualParams) =>
	callFunction<Types.AddManualParams, Types.AddManualResult>(
		data,
		FbFunctionName.FederationPartnershipAddManual
	);

const editManual = (data: Types.EditManualParams) =>
	callFunction<Types.EditManualParams, Types.EditManualResult>(
		data,
		FbFunctionName.FederationPartnershipEditManual
	);

const editCountManual = (data: Types.EditCountManualParams) =>
	callFunction<Types.EditCountManualParams, Types.EditCountManualResult>(
		data,
		FbFunctionName.FederationPartnershipEditCountManual
	);

const removeManual = (data: Types.RemoveManualParams) =>
	callFunction<Types.RemoveManualParams, Types.RemoveManualResult>(
		data,
		FbFunctionName.FederationPartnershipRemoveManual
	);

export const federationPartnershipDB = {
	model,
	addManual,
	editManual,
	editCountManual,
	removeManual,
};
