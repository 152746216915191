import update from 'immutability-helper';
import {
	FetchListState,
	FetchViewState,
	initalListState,
	initialViewState,
} from 'modules/firestore';
import { Reducer } from 'redux';
import {
	FederationAction,
	FederationDocData,
	FederationPartnershipDocData,
	FederationTypes,
} from './types';

export interface FederationState {
	view: FetchViewState<FederationDocData>;
	partnerships: FetchListState<FederationPartnershipDocData>;
}

export const initialState: FederationState = {
	view: initialViewState,
	partnerships: initalListState,
};

const reducer: Reducer<FederationState, FederationAction> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case FederationTypes.SET_VIEW_FEDERATION: {
			return update(state, {
				view: { $merge: action.payload.value },
			});
		}

		case FederationTypes.UPDATE_LIST_PARTNERSHIPS: {
			return update(state, { partnerships: { $merge: action.payload } });
		}

		case FederationTypes.CLEAR_FEDERATION: {
			return update(state, { $set: initialState });
		}

		default:
			return state;
	}
};

export default reducer;
