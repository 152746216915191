import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import UserMenu from './UserMenu';

interface Props {
	collapsed: boolean;
	setCollapsed: (value: boolean) => void;
}

const LayoutDashboardHeader: React.FC<Props> = ({
	collapsed,
	setCollapsed,
}) => {
	return (
		<Layout.Header
			className="flex flex-row justify-between w-full items-center"
			style={{ backgroundColor: 'white', height: 80 }}
		>
			{collapsed ? (
				<MenuUnfoldOutlined
					style={{ fontSize: 'large' }}
					onClick={() => setCollapsed(!collapsed)}
				/>
			) : (
				<MenuFoldOutlined
					style={{ fontSize: 'large' }}
					onClick={() => setCollapsed(!collapsed)}
				/>
			)}
			<UserMenu />
		</Layout.Header>
	);
};

export default LayoutDashboardHeader;
