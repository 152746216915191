import { LanguagesTypes } from '@glissandoo/lib/lang';
import antd from 'antd/lib/locale/es_ES';
import dateFns from 'date-fns/locale/es';
import set from 'lodash/set';
import messages from '../locales/es.json';

const esLang = {
	messages,
	// Al utilizar el data-fns en DatePicker no tiene bien asignadas los nombres de los locales. Intenta cargar es_ES y no lo encuentra
	antd: set(antd, 'DatePicker.lang.locale', 'es'),
	locale: LanguagesTypes.ES,
	dateFns,
};

export default esLang;
