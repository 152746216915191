import firebase from 'firebase/app';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from './root';

export interface FetchListState<D> {
	items: D[];
	ready: boolean;
	isLoading: boolean;
	error: boolean;
	loadMore: boolean;
	isLoadingMore: boolean;
	endDoc: firebase.firestore.DocumentSnapshot | null;
	firstDoc: firebase.firestore.DocumentSnapshot | null;
}

export interface FetchViewState<D> {
	data: D | null;
	ready: boolean;
	loading: boolean;
	error: boolean;
}

type ThunkResult<R> = ThunkAction<
	R,
	ApplicationState,
	undefined,
	Action<string>
>;

type FormatItemFn<D> = (
	doc: firebase.firestore.DocumentSnapshot,
	state: ApplicationState
) => D;

interface FirestoreListHandlerParams<D> extends FirestoreListParams<D> {
	action: ModuleListActions;
}

interface FirestoreViewHandlerParams<D> extends FirestoreViewParams<D> {
	action: ModuleViewActions;
}

interface FirestoreListParams<D> {
	list: FetchListState<D>;
	type: string;
	endpoint: firebase.firestore.Query<firebase.firestore.DocumentData>;
	formatItem: FormatItemFn<D>;
	itemsByPage: number;
}

interface FirestoreViewParams<D> {
	view: FetchViewState<D> | null;
	type: string;
	endpoint: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
	formatView: FormatItemFn<D>;
}

export enum ModuleListActions {
	INIT = 'init',
	LOADMORE = 'loadmore',
	REFRESH = 'refresh',
	NEWS = 'news',
}

export enum ModuleViewActions {
	INIT = 'init',
	REFRESH = 'refresh',
}

export const initialViewState = {
	loading: false,
	data: null,
	error: false,
	ready: false,
};

export const initalListState = {
	items: [],
	isLoading: false,
	error: false,
	loadMore: true,
	isLoadingMore: false,
	endDoc: null,
	ready: false,
	firstDoc: null,
};

export function getFirestoreViewHandler<D>({
	action,
	...rest
}: FirestoreViewHandlerParams<D>): ThunkResult<Promise<void>> {
	return async (dispatch) => {
		switch (action) {
			case ModuleViewActions.INIT: {
				return dispatch(getFirestoreViewInit(rest));
			}
			case ModuleViewActions.REFRESH: {
				return dispatch(getFirestoreViewRefresh(rest));
			}
			default: {
				return;
			}
		}
	};
}

export function getFirestoreViewRefresh<D>(
	params: FirestoreViewParams<D>
): ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const { view, type, endpoint, formatView } = params;
		const state = getState();
		const { id } = endpoint;

		if (!view || view.loading) {
			return Promise.resolve();
		}

		try {
			const doc = await endpoint.get();

			if (!doc.exists) {
				dispatch({
					type,
					payload: {
						id,
						value: { loading: false, error: true },
					},
				});
				return Promise.resolve();
			}

			const data = formatView(doc, state);

			dispatch({
				type,
				payload: {
					id,
					value: { data, loading: false, error: false },
				},
			});
			return Promise.resolve();
		} catch (error) {
			dispatch({
				type,
				payload: {
					id,
					value: {
						data: null,
						loading: false,
						error: true,
					},
				},
			});
			return Promise.reject(error);
		}
	};
}

export function getFirestoreViewInit<D>(
	params: FirestoreViewParams<D>
): ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const { view, type, endpoint, formatView } = params;
		const state = getState();
		const { id } = endpoint;

		if (view && view.loading) {
			return Promise.resolve();
		}

		if (!view) {
			dispatch({
				type,
				payload: {
					id,
					value: {
						data: null,
						loading: true,
						error: false,
						ready: false,
					},
				},
			});
		}

		try {
			const doc = await endpoint.get();

			if (!doc.exists) {
				dispatch({
					type,
					payload: {
						id,
						value: { ready: true, loading: false, error: true },
					},
				});
				return Promise.resolve();
			}

			const data = formatView(doc, state);

			dispatch({
				type,
				payload: {
					id,
					value: { data, loading: false, error: false, ready: true },
				},
			});
			return Promise.resolve();
		} catch (error) {
			dispatch({
				type,
				payload: {
					id,
					value: {
						data: null,
						loading: false,
						error: true,
						ready: true,
					},
				},
			});
			return Promise.reject(error);
		}
	};
}

export function getFirestoreListHandler<D>({
	action,
	...rest
}: FirestoreListHandlerParams<D>): ThunkResult<Promise<void>> {
	return async (dispatch) => {
		switch (action) {
			case ModuleListActions.INIT: {
				return dispatch(getFirestoreListInit(rest));
			}
			case ModuleListActions.LOADMORE: {
				return dispatch(getFirestoreListLoadMore(rest));
			}
			case ModuleListActions.NEWS: {
				return dispatch(getFirestoreListNews(rest));
			}
			case ModuleListActions.REFRESH: {
				return dispatch(getFirestoreListRefresh(rest));
			}
			default: {
				return;
			}
		}
	};
}

function getFirestoreListInit<D>(
	params: FirestoreListParams<D>
): ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const { list, type, endpoint, formatItem, itemsByPage } = params;
		const state = getState();
		const { isLoading } = list;

		if (isLoading) {
			return Promise.resolve();
		}

		try {
			dispatch({
				type,
				payload: { isLoading: true },
			});

			const q = await endpoint.limit(itemsByPage).get();

			const items = q.docs.map((doc) => formatItem(doc, state));

			dispatch({
				type,
				payload: {
					items,
					isLoading: false,
					firstDoc: q.docs[0] || null,
					endDoc: q.docs[q.docs.length - 1] || null,
					loadMore: q.size === itemsByPage,
					error: false,
					ready: true,
				},
			});

			return Promise.resolve();
		} catch (e) {
			dispatch({
				type,
				payload: {
					...initalListState,
					error: true,
					isLoading: false,
					ready: true,
				},
			});
			return Promise.reject(e);
		}
	};
}

function getFirestoreListLoadMore<D>(
	params: FirestoreListParams<D>
): ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const { list, type, endpoint, formatItem, itemsByPage } = params;
		const state = getState();
		const {
			endDoc,
			isLoading,
			isLoadingMore,
			loadMore,
			items: prevItems,
		} = list;

		if (!endDoc || !loadMore || isLoading || isLoadingMore) {
			return Promise.resolve();
		}

		try {
			dispatch({
				type,
				payload: { isLoadingMore: true },
			});

			const q = await endpoint
				.startAfter(endDoc)
				.limit(itemsByPage)
				.get();

			const items = q.docs.map((doc) => formatItem(doc, state));

			dispatch({
				type,
				payload: {
					items: prevItems.concat(items),
					isLoadingMore: false,
					endDoc: q.docs[q.docs.length - 1] || null,
					loadMore: q.size === itemsByPage,
					error: false,
				},
			});
		} catch (e) {
			dispatch({
				type: type,
				payload: {
					...initalListState,
					error: true,
					isLoadingMore: false,
				},
			});
		}
	};
}

function getFirestoreListNews<D>(
	params: FirestoreListParams<D>
): ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const { list, type, endpoint, formatItem } = params;
		const state = getState();
		const { firstDoc, isLoading, items: prevItems } = list;

		if (!firstDoc || isLoading) {
			return Promise.resolve();
		}

		try {
			dispatch({
				type,
				payload: { isLoading: true },
			});

			const q = await endpoint.endBefore(firstDoc).get();

			const items = q.docs.map((doc) => formatItem(doc, state));

			dispatch({
				type,
				payload: {
					items: items.concat(prevItems),
					isLoading: false,
					firstDoc: q.docs[0] || null,
					error: false,
				},
			});
		} catch (e) {
			dispatch({
				type: type,
				payload: {
					...initalListState,
					error: true,
					isLoading: false,
				},
			});
		}
	};
}

function getFirestoreListRefresh<D>(
	params: FirestoreListParams<D>
): ThunkResult<Promise<void>> {
	return async (dispatch, getState) => {
		const { list, type, endpoint, formatItem, itemsByPage } = params;
		const state = getState();
		const { isLoading } = list;

		if (isLoading) {
			return Promise.resolve();
		}

		try {
			dispatch({
				type,
				payload: { isLoading: true },
			});

			const q = await endpoint.limit(itemsByPage).get();

			const items = q.docs.map((doc) => formatItem(doc, state));

			dispatch({
				type,
				payload: {
					items: items,
					isLoading: false,
					endDoc: q.docs[q.docs.length - 1] || null,
					firstDoc: q.docs[0],
					loadMore: q.size === itemsByPage,
					error: false,
				},
			});
		} catch (e) {
			dispatch({
				type: type,
				payload: {
					...initalListState,
					error: true,
					isLoading: false,
				},
			});
		}
	};
}
