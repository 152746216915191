import { Spin } from 'antd';
import LayoutDashboard from 'layouts/Dashboard';
import { FC, lazy, Suspense } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { FederationProvider } from 'router/dashboard/FederationContext';

const Events = lazy(() => import('routes/Events'));
const PartnershipList = lazy(() => import('routes/Partnership/List'));
const PartnershipNew = lazy(() => import('routes/Partnership/New'));
const PartnershipView = lazy(() => import('routes/Partnership/View'));
const PartnershipEdit = lazy(() => import('routes/Partnership/Edit'));
const Settings = lazy(() => import('routes/Settings'));

const DashboardRoute: FC<RouteProps> = ({ ...other }) => {
	return (
		<FederationProvider>
			<LayoutDashboard>
				<Route
					{...other}
					render={() => (
						<Suspense fallback={<Spin />}>
							<Switch>
								<Route
									exact
									path="/partnership"
									component={PartnershipList}
								/>
								<Route
									exact
									path="/partnership/new"
									component={PartnershipNew}
								/>
								<Route
									exact
									path="/partnership/:id"
									component={PartnershipView}
								/>
								<Route
									exact
									path="/partnership/:id/edit"
									component={PartnershipEdit}
								/>
								<Route
									exact
									path="/events"
									component={Events}
								/>
								<Route
									exact
									path="/settings"
									component={Settings}
								/>
								<Route
									render={() => (
										<Redirect to="/partnership" />
									)}
								/>
							</Switch>
						</Suspense>
					)}
				/>
			</LayoutDashboard>
		</FederationProvider>
	);
};

export default DashboardRoute;
