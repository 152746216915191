import { CollectionNames } from '@glissandoo/lib/helpers/collections';
import User from '@glissandoo/lib/models/User';
import { UserData } from '@glissandoo/lib/models/User/types';
import { DocumentData, DocumentSnapshot } from '@google-cloud/firestore';
import firebase from 'firebase/app';

const model = (doc: firebase.firestore.DocumentSnapshot) =>
	new User(doc as unknown as DocumentSnapshot<DocumentData>);

const update = (userId: string, data: Partial<UserData>) =>
	firebase
		.firestore()
		.collection(CollectionNames.User)
		.doc(userId)
		.update(data);

export const userDB = {
	model,
	update,
};
