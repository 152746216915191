import User from '@glissandoo/lib/models/User';
import { FetchViewState } from 'modules/firestore';
import { Action } from 'redux';

export enum UserParams {
	Id = 'id',
	Displayname = 'displayName',
	PhotoURL = 'photoURL',
	Language = 'language',
	Username = 'username',
	IsSuperAdmin = 'isSuperAdmin',
	FederationId = 'federationId',
}

export type AuthUserDocData = Pick<User, UserParams>;

export interface AuthState {
	user: FetchViewState<AuthUserDocData>;
	logged?: boolean;
}

export enum AuthTypes {
	SET_USER = 'SET_USER',
	UPDATE_USER = 'UPDATE_USER',
	SET_LOGGED = 'SET_LOGGED',
	CLEAR_ME = 'CLEAR_ME',
}

interface SetUserAction extends Action<AuthTypes> {
	type: AuthTypes.SET_USER;
	payload: {
		id: string;
		value: FetchViewState<AuthUserDocData>;
	};
}

interface UpdateUserMeAction extends Action {
	type: AuthTypes.UPDATE_USER;
	payload: Partial<AuthUserDocData>;
}

interface SetLoggedAction extends Action<AuthTypes> {
	type: AuthTypes.SET_LOGGED;
	payload: boolean;
}

interface ClearMeAction extends Action<AuthTypes> {
	type: AuthTypes.CLEAR_ME;
}

export type AuthActions =
	| SetUserAction
	| UpdateUserMeAction
	| SetLoggedAction
	| ClearMeAction;
