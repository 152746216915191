import {
	CalendarOutlined,
	SettingOutlined,
	TeamOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Typography } from 'antd';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { useFederation } from 'router/dashboard/FederationContext';

interface Props {
	collapsed: boolean;
}

const Sidebar: React.FC<Props> = ({ collapsed }) => {
	const location = useLocation();
	const history = useHistory();
	const federation = useFederation();

	const selectedKey = useMemo(() => {
		const { pathname } = location;
		return pathname.split('/')[1];
	}, [location]);

	return (
		<Layout.Sider
			trigger={null}
			collapsible
			collapsed={collapsed}
			className="min-h-screen"
		>
			<div className="flex flex-col justify-center items-center my-8 mx-4">
				<img
					className="rounded mb-4"
					width={collapsed ? 32 : 64}
					height={collapsed ? 32 : 64}
					src={federation.photoURL}
					alt="logo"
				/>
				{collapsed ? null : (
					<div className="px-4 text-center">
						<Typography.Title
							level={4}
							type="secondary"
							style={{ color: 'white' }}
							ellipsis={{ rows: 3 }}
						>
							{federation.displayName}
						</Typography.Title>
					</div>
				)}
			</div>
			<Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys={['partnership']}
				selectedKeys={[selectedKey]}
				onClick={({ key }) => history.push(`/${key}`)}
			>
				<Menu.Item key="partnership" icon={<TeamOutlined />}>
					<FormattedMessage id="layout.partnerships" />
				</Menu.Item>
				<Menu.Item key="events" icon={<CalendarOutlined />}>
					<FormattedMessage id="layout.events" />
				</Menu.Item>
				<Menu.Item key="settings" icon={<SettingOutlined />}>
					<FormattedMessage id="layout.settings" />
				</Menu.Item>
			</Menu>
		</Layout.Sider>
	);
};

export default Sidebar;
